
import Swiper from 'swiper';
import { Pagination, EffectFade, Autoplay, Controller, Scrollbar, Manipulation } from 'swiper/modules';

FontAwesomeConfig = { searchPseudoElements: true };
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/brands';


const mastheadSlider = new Swiper('.hp-masthead__slider', {
    modules: [EffectFade, Pagination, Autoplay, Controller],
    slidesPerView: 1,
    spaceBetween: 0,
    speed: 1000,
    loop: true,
    effect: "fade",

    pagination: {
        el: '.hp-testimonials__pagination',
        clickable: true,
    },

    fadeEffect: {
        crossFade: true,
    },

    autoplay: {
        delay: 8000,
    },
});

const testimonialsSlider = new Swiper('.hp-testimonials__slider', {
    modules: [Pagination, Autoplay, Controller],
    slidesPerView: 1,
    spaceBetween: 80,
    speed: 700,
    loop: true,

    pagination: {
        el: '.hp-testimonials__pagination',
        clickable: true,
    },

    fadeEffect: {
        crossFade: true,
    },

    autoplay: {
        delay: 8000,
    },
});

const accreditationsSlider = new Swiper('.hp-accreditations__slider', {
    modules: [Autoplay, Controller],
    slidesPerView: 2,
    spaceBetween: 40,
    speed: 700,

    autoplay: {
        delay: 8000,
    },

    breakpoints: {
        480: {
            slidesPerView: 2.5,
        },
        640: {
            slidesPerView: 3,
        },
        768: {
            slidesPerView: 4,
        },
        1024: {
            slidesPerView: 5,
        },
        1280: {
            slidesPerView: 6,
        },
    },
});

const tabsSlider = new Swiper('.hp-tabs__slider', {
    modules: [Scrollbar, Manipulation],
    slidesPerView: 1,
    spaceBetween: 30,

    scrollbar: {
        el: '.hp-tabs__scrollbar',
        hide: false,
    },

    breakpoints: {

        480: {
            slidesPerView: 1.75,
        },
        640: {
            slidesPerView: 2.5,
        },
        768: {
            slidesPerView: 3,
        },
        1024: {
            slidesPerView: 3.5,
        },
        1199: {
            slidesPerView: 4,
        },
    },
});

function fetchProducts(categoryId, callback) {
    jQuery.ajax({
        url: '/wp-admin/admin-ajax.php',
        type: 'POST',
        data: {
            'category_id': categoryId,
            'action': 'fetch_products'
        },
        success: function(response) {
            callback(response);
        }
    });
}

function loadCategoryProducts(categoryId) {
    fetchProducts(categoryId, function(newSlidesHtml) {
        tabsSlider.removeAllSlides();
        var slides = newSlidesHtml.trim().split(/(?=<div class="swiper-slide")/); // This regex splits the HTML string into individual slides
        tabsSlider.appendSlide(slides);
        
        tabsSlider.update();
    });
}

document.querySelectorAll('.hp-tabs__header__item button').forEach(button => {
    button.addEventListener('click', function() {

        document.querySelectorAll('.hp-tabs__header__item button').forEach(btn => {
            btn.classList.remove('active');
        });

        this.classList.add('active');

        let categoryId = this.getAttribute('data-category-id');
        loadCategoryProducts(categoryId);
    });
});


loadCategoryProducts('20'); 

const defaultCatButton = document.querySelector('.hp-tabs__header__item button[data-category-id="20"]');

if (defaultCatButton) {
    defaultCatButton.classList.add('active');
}


/* SORTING & FILTERS MOBILE */
document.addEventListener("DOMContentLoaded", function() {
    var filterButton = document.querySelector('.filter-toggle');
    var woocommerceSidebar = document.querySelector('.shop__sidebar');
  
    if(filterButton) {
        filterButton.addEventListener('click', function(event) {
            event.preventDefault();
            woocommerceSidebar.classList.toggle('show');
            woocommerceTop.classList.remove('show');
        }); 
    }
  
    var closeSidebarButton = document.querySelector('.shop__sidebar__close');
  
    if(closeSidebarButton) {
        closeSidebarButton.addEventListener('click', function(event) {
            event.preventDefault();
            woocommerceSidebar.classList.remove('show');
        });
    }
});

jQuery(document).ready(function($) {

 /* ====================== REMOVE EMPTY P TAG ======================  */
    $('p').each(function() {
        var $this = $(this);
        if ($this.html().replace(/\s|&nbsp;/g, '').length == 0)
            $this.remove();
    });

/* ====================== SEARCH ======================  */

$('#header-search').on('click', function(e) {
    e.preventDefault();
    $('.search-form').addClass('active');
    $('.search-form input').focus();
});

$('.search-form-close').on('click', function(e) {
    e.preventDefault();
    $('.search-form').removeClass('active');
});

/* ====================== WOOCOM QUANTITY SELECTOR   ======================  */


// Decrement quantity
$('body').on('click', '.quantity .minus', function(e) {
    e.preventDefault();
    console.log("Minus button clicked");

    var $input = $(this).parent().find('.qty');
    if ($input.length === 0) {
        console.log("No input field found for minus button");
        return; // Exit the function if no input found
    }

    var count = parseInt($input.val(), 10) - 1;
    count = count < 1 ? 1 : count;
    console.log("New count after decrement: " + count);

    $input.val(count).change();
});

// Increment quantity
$('body').on('click', '.quantity .plus', function(e) {
    e.preventDefault();
    console.log("Plus button clicked");

    var $input = $(this).parent().find('.qty');
    if ($input.length === 0) {
        console.log("No input field found for plus button");
        return; // Exit the function if no input found
    }

    var count = parseInt($input.val(), 10) + 1;
    console.log("New count after increment: " + count);

    $input.val(count).change();
});

// Enable update cart button when quantity changes
$('body').on('change', '.quantity .qty', function() {
    console.log("Quantity changed");

    var isFormCartPresent = $(this).closest('form.cart').length > 0;
    console.log("Is 'form.cart' present: " + isFormCartPresent);

    $(this).closest('form.cart').find('button[name="update_cart"]').prop('disabled', false);
});

$('.tab-link').click(function() {
    var tab_id = $(this).attr('data-tab'); // Get the data-tab value

    // Remove current class from all tabs and content
    $('.tab-link').removeClass('current');
    $('.tab-content').removeClass('current');

    // Add the current class to the clicked tab and the associated content
    $(this).addClass('current');
    $("#" + tab_id).addClass('current');
});

$('.header__mobile').on('click', function() {
    $('.nav-toggle').toggleClass('nav-toggle--open');
    $(this).toggleClass('active');
    $('.header').toggleClass('header--open');
    $("html, body").toggleClass("noscroll");
})

if ($(window).width() < 1025) {
    $('.menu-item-has-children > a').on('click', function(e) {
        e.preventDefault();

        var $parentMenuItem = $(this).parent('.menu-item-has-children');
        var $subMenu = $parentMenuItem.find('.sub-menu');
        var parentLink = $(this).attr('href'); // Capture the href
        var parentLinkText = $(this).text(); // Capture the link text
        var newLinkText = 'View all ' + parentLinkText; // Create the new link text

        // Toggle active class on the sub-menu
        $subMenu.toggleClass('active');

        // Add a back button and the original link with modified text
        if($subMenu.hasClass('active')){
            if(!$subMenu.find('.original-link').length){
                $subMenu.prepend('<li class="original-link"><a href="' + parentLink + '">' + newLinkText + '</a></li>');
            }

            if(!$subMenu.find('.back-button').length){
                $subMenu.prepend('<li class="back-button"><a href="#"><svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6"><path id="Polygon_1" data-name="Polygon 1" d="M4,0,8,6H0Z" transform="translate(8 6) rotate(180)" fill="#0e0f0e"/></svg>Back</a></li>');
            }
        }
    });
}

// Handle click event for back button within sub-menu
$(document).on('click', '.back-button', function(e) {
    e.preventDefault();
    $(this).closest('.sub-menu').removeClass('active');
});



// Handle click event for back button within sub-menu
$(document).on('click', '.back-button', function(e) {
    e.preventDefault();
    $(this).closest('.sub-menu').removeClass('active');
});


//product page weight display
$('form.variations_form').on('found_variation', function(event, variation) {
    if (variation.weight) {
        $('.product-weight').text('Weight: ' + variation.weight + ' kg');
    } else {
        $('.product-weight').text('');
    }
}).on('reset_data', function() {
    $('.product-weight').text('');
});


/* POSTCODE CHECKER AJAX */

$('.postcode-form').on('submit', function(e) {
    e.preventDefault();
    var postcode = $('input[name="postcode"]').val();

    $.ajax({
        url: '../wp-admin/admin-ajax.php',
        type: 'POST',
        data: {
            action: 'check_postcode',
            postcode: postcode
        },
        success: function(response) {
            response = JSON.parse(response);
            if(response.valid) {
                alert('We deliver to your area! Delivery cost: £' + response.cost);
            } else {
                alert(response.message);
            }
        }
    });
});

/* ====================== INIT FUNCSTIONS ======================  */

/* ====================== WINDOW REISZE FUNCTION  ======================  */
  $(window).resize(function() {
  })
});
